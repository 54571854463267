import { get, isEmpty, sortBy } from 'lodash';
import { EXAM_FIELD_PATH } from 'modules/hesi/redux/exam-order/exam-order.selectors';
import { ExamOrderStatuses, PAYMENT_METHOD_OPTIONS, PAYMENT_METHODS } from 'modules/hesi/constants/exam-order.constant';
import {
  ExamOrderItemEditorModel,
  ExamOrderStatusModel,
  ProgramExamOrderItem,
  ProgramExamOrderItemInfo,
  ExamPymtOderProgram,
  ExamOrderMoreInfoModel,
  ExamPymtOrderModel } from '../../models/examOrder.model';
import { ExamProductModel, ProgramModel, PagingOptions, ProgramSelectionModel } from '../../models/exam.model';

export const getTotalQuantity = programsSelected => {
  let sum = 0;
  programsSelected.forEach(program => {
    sum += Number(program.quantity);
  });
  return sum;
};

export const getTotalCartPrice = (orderItems: ExamOrderItemEditorModel[]) => orderItems.reduce((result, item) => result + getTotalQuantity(item.programsSelected) * item.price, 0);

export const validateEmptyFieldInOrderItem = (orderItem, field) => {
  if (!orderItem || !orderItem.programsSelected) {
    return true;
  }
  return orderItem.programsSelected.find(program => !program[field] || program[field].length === 0);
};

export const getAllProgramIdsOfOrderItem = (orderItem: ExamOrderItemEditorModel) =>
  (orderItem && orderItem.programsSelected && orderItem.programsSelected.map(program => program.programId)) || [];

export const getAllProgramsNameOfOrderItem = (orderItem: ExamOrderItemEditorModel, programs: ProgramModel[]) => {
  const programIds = getAllProgramIdsOfOrderItem(orderItem);
  const programsName = programIds.map(id => {
    const programItem = programs.find(program => String(program.programId) === id);
    return programItem ? programItem.programName : '';
  });
  return programsName && programsName.length > 0 && programsName.join(', ');
};

export const getAllFacultiesNameOfOrderItem = (orderItem: ExamOrderItemEditorModel) => {
  const facultiesName = orderItem.programsSelected.map(program => program.facultyUserNames.map(faculty => `${faculty.lastName} ${faculty.firstName}`).join(', '));
  return facultiesName && facultiesName.length > 0 && facultiesName.join(', ');
};

export const generateOrderItemId = () => Date.now();

export const isEmptyOrderItems = orderItems => !orderItems || orderItems.length === 0;

export const getValueFromObjectByKey = (field: string, object?: {}) => {
  if (!object || !object[field]) {
    return '';
  }
  return object[field];
};

export const buildErrorMessage = (field, errors) => errors || { [field]: '' };
// Search
const isValueIncluded = (value: string | number, searchText: string) => `${value}`.toLocaleLowerCase().includes(searchText.trim().toLowerCase());

const isFieldIncluded = (examListData: ExamProductModel, path: string, searchText: string) => {
  const value = get(examListData, path);
  return value && isValueIncluded(value, searchText);
};

export const filterExamsBySearchText = (searchText: string) => (examProducts: ExamProductModel[]) =>
  examProducts && examProducts.filter(product => !searchText || isFieldIncluded(product, EXAM_FIELD_PATH.NAME, searchText));

export const filterExamsByProgramExamTypeFilter = (pagingOptions: PagingOptions) => (examProducts: ExamProductModel[]) => {
  if (!examProducts || examProducts.length === 0) {
    return examProducts;
  }

  const selectedProgramTypes = new Set(pagingOptions.selectedProgramTypes);
  const selectedAssessmentTypes = new Set(pagingOptions.selectedAssessmentTypes);

  return (
    examProducts &&
    examProducts.filter(
      product =>
        product &&
        product.programTypes &&
        product.programTypes.some(pt => {
          const programTypeMatch = selectedProgramTypes.size === 0 || selectedProgramTypes.has(pt.programTypeId);
          const assessmentTypeMatch = selectedAssessmentTypes.size === 0 || pt.examTypes.some(examType => selectedAssessmentTypes.has(examType.assessmentType.assessmentTypeId));
          return programTypeMatch && assessmentTypeMatch;
        })
    )
  );
};

export const getProgramNameByProgramId = (programId: number | string, programList: ProgramModel[]) => {
  const program = programList.find(item => item.programId === Number(programId));
  return program ? program.programName : '';
};

export const buildExamOrderPayload = (paymentInformation, orderItems, paymentMethod) => {
  let payload = {};
  const {
    orderingFacultyContactPhone,
    orderingFacultyContactPhoneExtension,
    billingAddress,
    secondaryContactFullName,
    secondaryContactTitle,
    secondaryContactEmail,
    programExamOrderItems
  } = paymentInformation;
  payload = {
    paymentMethod,
    contactPhoneNumber: orderingFacultyContactPhone,
    contactPhoneExtension: orderingFacultyContactPhoneExtension,
    examOrderItems: orderItems.map(item => {
      const { programsSelected, productId } = item;
      return {
        productId,
        programs: programsSelected.map(program => {
          const { programId, facultyUserNames, quantity } = program;
          return {
            programId: Number(programId),
            facultyUserNames: facultyUserNames.map(faculty => faculty.userName),
            quantity
          };
        })
      };
    }),
    programs: programExamOrderItems.map(item => {
      const { programId, invoicePONumber, programEditsRequest } = item;
      if (isEmpty(programEditsRequest)) {
        return { programId: Number(programId), invoicePONumber };
      }

      return { programId: Number(programId), invoicePONumber, ...programEditsRequest };
    })
  };

  if (secondaryContactFullName) {
    payload = {
      ...payload,
      secondaryContactFullName,
      secondaryContactTitle,
      secondaryContactEmail
    };
  }

  if (paymentMethod === PAYMENT_METHODS.CREDIT_CARD) {
    return {
      ...payload,
      billingAddress
    };
  }

  return payload;
};
export const buildExamOrderMorePayload = (paymentInformation, orderItems, examOrderId) => {
  const {
    orderingFacultyContactPhone,
    orderingFacultyContactPhoneExtension,
    secondaryContactFullName,
    secondaryContactTitle,
    secondaryContactEmail,
    programExamOrderItems
  } = paymentInformation;

  const examOrderItems = orderItems.map(({ programsSelected, orderItemId }) => ({
    examOrderItemId: `${orderItemId}`,
    programs: programsSelected.map(({ programId, quantity }) => ({
      programId: Number(programId),
      quantity
    }))
  }));

  const programs = programExamOrderItems.map(({ programId, invoicePONumber, programEditsRequest }) =>
    (isEmpty(programEditsRequest) ? { programId: Number(programId), invoicePONumber } : { programId: Number(programId), invoicePONumber, ...programEditsRequest }));

  return {
    examOrderId,
    contactPhoneNumber: orderingFacultyContactPhone,
    contactPhoneExtension: orderingFacultyContactPhoneExtension,
    examOrderItems,
    programs,
    ...(secondaryContactFullName && {
      secondaryContactFullName,
      secondaryContactTitle,
      secondaryContactEmail
    })
  };
};

const buildProgramExamOrderItemInfo = (examOrderItem: ExamOrderItemEditorModel, program: ProgramSelectionModel) => {
  const { productId, name, price, programTypes } = examOrderItem;
  const { quantity, facultyUserNames } = program;

  return {
    productId,
    name,
    price,
    programTypes,
    facultyUserNames,
    quantity
  };
};

const buildProgramExamOrderItem = (examOrderItem: ExamOrderItemEditorModel, program: ProgramSelectionModel) => {
  const { programId, programName, poRequiredFlag, billingAddress, shippingAddress, taxRegistrationNumber, taxExemptFlag, customerName, customerEmail,
    bookStoreFlag, contractedPricingFlag, creditHoldFlag, prePaymentRequiredFlag
  } = program;

  return {
    programId,
    programName,
    poRequiredFlag,
    billingAddress,
    shippingAddress,
    taxRegistrationNumber,
    taxExemptFlag,
    customerName,
    customerEmail,
    bookStoreFlag,
    contractedPricingFlag,
    creditHoldFlag,
    prePaymentRequiredFlag,
    programExamOrderItemInfos: [buildProgramExamOrderItemInfo(examOrderItem, program)]
  };
};

export const buildProgramExamOrderItems = (examOrderItems: ExamOrderItemEditorModel[]) => {
  const programExamOrderItemsMap = {};
  examOrderItems.forEach(examOrderItem => {
    const { programsSelected } = examOrderItem;
    programsSelected.forEach(program => {
      const { programId } = program;
      if (programExamOrderItemsMap[programId]) {
        programExamOrderItemsMap[programId].programExamOrderItemInfos.push(buildProgramExamOrderItemInfo(examOrderItem, program));
      } else {
        programExamOrderItemsMap[programId] = buildProgramExamOrderItem(examOrderItem, program);
      }
    });
  });
  const sortedProgramItems = sortBy(Object.values(programExamOrderItemsMap), 'programName');

  const sortedProgramExamOrderItems = sortedProgramItems.map(program => ({
    ...program,
    programExamOrderItemInfos: sortBy(program.programExamOrderItemInfos, 'name')
  }));

  return sortedProgramExamOrderItems as ProgramExamOrderItem[];
};

export const buildProgramExamOrderHistoryItems = (examOrderHistoryItem: ExamPymtOrderModel[]) => examOrderHistoryItem.map(originalItem => {
  const programExamOrderHistoryItems = {};
  const { examOrderItems } = originalItem;
  examOrderItems.forEach(examOrderItem => {
    const { programId, programName } = examOrderItem;
    if (!programExamOrderHistoryItems[programId]) {
      programExamOrderHistoryItems[programId] = {
        programId,
        programName,
        programExamOrderHistoryItemInfos: [],
      };
    }
    programExamOrderHistoryItems[programId].programExamOrderHistoryItemInfos.push(examOrderItem);
  });

  const sortedProgramHistoryItems = sortBy(Object.values(programExamOrderHistoryItems), 'programName');
  const sortedProgramExamOrderHistoryItems = sortedProgramHistoryItems.map(({ programId, programName, programExamOrderHistoryItemInfos }) => ({
    programId,
    programName,
    programExamOrderHistoryItemInfos,
  }));

  return {
    ...originalItem,
    examOrderItems: sortedProgramExamOrderHistoryItems
  };
});

export const isMultipleProgramsSelected = (programExamOrderItems: ProgramExamOrderItem[]) => programExamOrderItems && programExamOrderItems.length > 1;

export const generatePaymentMethodOptions = (programExamOrderItems: ProgramExamOrderItem[]) => {
  const initialFlags = {
    prePaymentRequiredFlag: false,
    contractedPricingFlag: false,
    bookStoreFlag: false,
  };
  const examOrderFlags = programExamOrderItems.reduce((acc, programExamOrderItem) => ({
    prePaymentRequiredFlag: acc.prePaymentRequiredFlag || programExamOrderItem.prePaymentRequiredFlag,
    contractedPricingFlag: acc.contractedPricingFlag || programExamOrderItem.contractedPricingFlag,
    bookStoreFlag: acc.bookStoreFlag || programExamOrderItem.bookStoreFlag,
  }), initialFlags);

  const { prePaymentRequiredFlag, contractedPricingFlag, bookStoreFlag } = examOrderFlags;

  const isUnHappyOrder = (prePaymentRequiredFlag && contractedPricingFlag) || (contractedPricingFlag && bookStoreFlag);
  const isNoFlagged = !prePaymentRequiredFlag && !contractedPricingFlag && !bookStoreFlag;

  if (isUnHappyOrder || isNoFlagged || bookStoreFlag) {
    return [
      PAYMENT_METHOD_OPTIONS.DEFAULT,
      PAYMENT_METHOD_OPTIONS.CREDIT_CARD,
      PAYMENT_METHOD_OPTIONS.BILL_MY_INSTITUTION,
      PAYMENT_METHOD_OPTIONS.BILL_EACH_STUDENT
    ];
  }

  if (contractedPricingFlag) {
    return [
      PAYMENT_METHOD_OPTIONS.DEFAULT,
      PAYMENT_METHOD_OPTIONS.BILL_MY_INSTITUTION
    ];
  }

  if (prePaymentRequiredFlag) {
    return [
      PAYMENT_METHOD_OPTIONS.DEFAULT,
      PAYMENT_METHOD_OPTIONS.CREDIT_CARD
    ];
  }

  return [
    PAYMENT_METHOD_OPTIONS.DEFAULT,
    PAYMENT_METHOD_OPTIONS.CREDIT_CARD,
    PAYMENT_METHOD_OPTIONS.BILL_MY_INSTITUTION,
    PAYMENT_METHOD_OPTIONS.BILL_EACH_STUDENT
  ];
};

export const getPaymentMethodOptions = (programExamOrderItems: ProgramExamOrderItem[], hesiAlcOrderPilotSupport: boolean) => {
  if (!programExamOrderItems) {
    return [];
  }

  const paymentMethodOptions = generatePaymentMethodOptions(programExamOrderItems);
  if (hesiAlcOrderPilotSupport) {
    return paymentMethodOptions.filter(option => option === PAYMENT_METHOD_OPTIONS.DEFAULT || option === PAYMENT_METHOD_OPTIONS.BILL_MY_INSTITUTION);
  }
  return paymentMethodOptions;
};

export const caculateProgamExamOrderSubtotal = (examOrderItems: ProgramExamOrderItemInfo[]) =>
  examOrderItems && examOrderItems.reduce((result, item) => result + item.quantity * item.price, 0);

export const getExamOrderStatusName = (examOrderStatus: ExamOrderStatusModel) => {
  if (!examOrderStatus) {
    return '-';
  }

  const { id, name } = examOrderStatus;
  if (id === ExamOrderStatuses.Failed.id) {
    return ExamOrderStatuses.Failed.name;
  }

  return name;
};

export const isOvercomeLimitOfNumberOfPrograms = (examOrderItems: ExamOrderItemEditorModel[], selectedProgramIds: string[], maxProgramsNumber: number) => {
  const programIds = [];
  if (examOrderItems && examOrderItems.length > 0) {
    examOrderItems.forEach(examOrderItem => {
      examOrderItem.programsSelected.forEach(program => {
        if (!programIds.includes(program.programId)) {
          programIds.push(program.programId);
        }
      });
    });
  }

  return new Set(programIds.concat(selectedProgramIds)).size > maxProgramsNumber;
};

const isCreditHoldProgram = (program: ProgramModel) => !!program.creditHoldFlag;

export const isInvalidProgram = (program: ProgramModel) => isCreditHoldProgram(program);

export const getPoNumberByProgramId = (programs: ExamPymtOderProgram[], id: number | string) => {
  const purchasedProgram = programs && programs.find((program: ExamPymtOderProgram) => program.programId === Number(id));
  return purchasedProgram ? purchasedProgram.poNumber : '-';
};

const buildExamOrderItemEditor = (examOrderItem) => {
  const { examOrderItemId, productId, productName, productPrice, programs } = examOrderItem;
  return {
    orderItemId: Number(examOrderItemId),
    productId,
    name: productName,
    price: Number(productPrice),
    programsSelected: programs.map(program => {
      const { programId, programName, associatedFaculties, billingAddress, shippingAddress, taxRegistrationNumber, taxExemptFlag, customerName, customerEmail,
        distanceTestingFlag, poRequiredFlag, creditHoldFlag, contractedPricingFlag, prePaymentRequiredFlag, bookStoreFlag, isResourceFromHesi, organizationName
      } = program;
      return {
        programId: programId.toString(),
        programName,
        organizationName,
        quantity: 0,
        billingAddress,
        shippingAddress,
        taxRegistrationNumber,
        taxExemptFlag,
        customerName,
        customerEmail,
        distanceTestingFlag,
        poRequiredFlag,
        creditHoldFlag,
        contractedPricingFlag,
        prePaymentRequiredFlag,
        bookStoreFlag,
        isResourceFromHesi,
        facultyUserNames: associatedFaculties
      };
    })
  } as ExamOrderItemEditorModel;
};

export const buildExamOrderEditor = (examOrder: ExamOrderMoreInfoModel) => {
  const { examOrderId, examOrderPaymentMethod: paymentMethod } = examOrder;
  return {
    examOrderId,
    paymentMethod,
    examOrderItems: [buildExamOrderItemEditor(examOrder)]
    // examOrderItems: examOrderItems.map(item => buildExamOrderItemEditor(item))
  };
};
