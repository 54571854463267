import { Redirect, Switch } from 'react-router';
import ErrorBoundary from 'components/error-boundary/ErrorBoundary.component';
import HESILayout from 'modules/hesi/components/layout/HESILayout.component';
import { HESISecutityRoutes } from 'modules/hesi/routes/secutity.routes';
import { createUserRoutes } from 'utilities/route/route.utility';
import { ALCRoutePath } from 'modules/hesi/constants/hesi.constants';
import { ALCRoutes } from 'modules/hesi/routes/hesi.routes';
import { createExamOrderRoutes } from 'modules/hesi/utilities/route/route.utility';

const examOrderMoreSecurityRoutes = createUserRoutes(HESISecutityRoutes.getExamOrderMoreSecurityRoutes());
const examOrderMoreRoutes = createExamOrderRoutes(ALCRoutes.getExamOrderMoreRoutes());

const ExamOrderMoreHome = () => (
  <HESILayout>
    <ErrorBoundary>
      <Switch>
        {examOrderMoreSecurityRoutes}
        {examOrderMoreRoutes}
        <Redirect to={ALCRoutePath.examOrderMore.error.pageNotFound} />
      </Switch>
    </ErrorBoundary>
  </HESILayout>
);

export default ExamOrderMoreHome;
