/* eslint-disable max-len */
import * as yup from 'yup';

export const CMS_GENERATED = 'CMS Generated';
const INVOICE_SYSTEM_GENERATED = 'Invoice System Generated';

export const InvoiceStatuses = {
  UnprocessedInstallment: { id: 0, name: 'Unprocessed Installment' },
  InvoicePending: { id: 1, name: 'Invoice Pending' },
  InvoiceBillingApproved: { id: 5, name: 'Invoice Approved' },
  InvoicePayloadSent: { id: 6, name: 'Invoice Payload Sent', defaultContact: CMS_GENERATED },
  InvoicePastDue: { id: 7, name: 'Invoice Past Due', defaultContact: CMS_GENERATED },
  InvoicePayloadSentFailed: { id: 14, name: 'Invoice Payload Sent Failed', defaultContact: CMS_GENERATED },
  InvoiceCreditPayloadSent: { id: 32, name: 'Invoice Credit Payload Sent', defaultContact: CMS_GENERATED },
  InvoiceCreditPayloadSentFailed: { id: 33, name: 'Invoice Credit Payload Sent Failed', defaultContact: CMS_GENERATED },
  InvoiceFailed: { id: 8, name: 'Invoice Failed', defaultContact: INVOICE_SYSTEM_GENERATED },
  InvoiceCreated: { id: 9, name: 'Invoice Created', defaultContact: INVOICE_SYSTEM_GENERATED },
  InvoiceSent: { id: 10, name: 'Invoice Sent', defaultContact: INVOICE_SYSTEM_GENERATED },
  InvoiceClosed: { id: 11, name: 'Invoice Closed', defaultContact: INVOICE_SYSTEM_GENERATED },
  InvoiceOpened: { id: 12, name: 'Invoice Opened', defaultContact: INVOICE_SYSTEM_GENERATED },
  InvoiceDrafted: { id: 26, name: 'Invoice Drafted', defaultContact: INVOICE_SYSTEM_GENERATED },
  RevenueAllocationUploadFailed: { id: 15, name: 'Revenue Allocation Upload Failed', defaultContact: CMS_GENERATED },
  RevenueAllocationFileGenerated: { id: 16, name: 'Revenue Allocation File Generated', defaultContact: CMS_GENERATED },
  RevenueAllocationNotCalculated: { id: 16, name: 'Revenue Allocation Not Calculated', defaultContact: CMS_GENERATED },
  RevenueAllocationUploadSuccess: { id: 18, name: 'Revenue Allocation Upload Success', defaultContact: CMS_GENERATED },
  InvoiceCreditMemoCreated: { id: 37, name: 'Invoice Credit Memo Created' },
  InvoiceCreditMemoSent: { id: 38, name: 'Invoice Credit Memo Sent' },
  ReverseRevenueAllocationFileGenerated: { id: 40, name: 'Reverse Revenue Allocation File Generated' },
  ReverseRevenueAllocationUploadSuccess: { id: 42, name: 'Reverse Revenue Allocation Upload Success' },
  InvoiceCreditMemoFailed: { id: 36, name: 'Invoice Credit Memo Failed' },
  InvoiceIgnore: { id: 54, name: 'Invoice Ignore' },
  HesiExamOrderInvoicePending: { id: 71, name: 'Pending' },
  HesiExamOrderInvoiceFailed: { id: 72, name: 'Failed' },
  HesiExamOrderInvoiceProcessing: { id: 73, name: 'Processing' },
  HesiExamOrderInvoiceProcessed: { id: 74, name: 'Processed' },
  HesiExamOrderItemCreated: { id: 75, name: 'Order Submitted' },
  HesiExamOrderItemStatusUpdated: { id: 76, name: 'Item Status Updated' },
  HesiExamOrderItemExpirationDateUpdated: { id: 77, name: 'Expiration Date Updated' },
  HesiExamOrderItemFacultyPermissionsUpdated: { id: 78, name: 'Faculty Permissions Updated' },
  HesiExamOrderItemNoChargeLicensesAdded: { id: 79, name: 'No Charge Licenses Added' },
  HesiExamOrderItemPricePerStudentUpdated: { id: 80, name: 'Price Per Student Updated' },
  HesiExamOrderItemNoteAdded: { id: 81, name: 'Note Added' },
  HesiExamOrderItemAdditionalLicensesAdded: { id: 83, name: 'Additional Licenses Added' }
};

export const InvoiceCMSStatuses = [
  {
    key: 'invoice-pending',
    value: InvoiceStatuses.InvoicePending.name
  },
  {
    key: 'invoice-approved',
    value: InvoiceStatuses.InvoiceBillingApproved.name
  },
  {
    key: 'invoice-payload-sent',
    value: InvoiceStatuses.InvoicePayloadSent.name
  },
  {
    key: 'invoice-payload-sent-failed',
    value: InvoiceStatuses.InvoicePayloadSentFailed.name
  },
  {
    key: 'invoice-past-due',
    value: InvoiceStatuses.InvoicePastDue.name
  },
  {
    key: 'invoice-ignore',
    value: InvoiceStatuses.InvoiceIgnore.name
  }
];

export const InvoiceOracleStatuses = [
  {
    key: 'invoice-failed',
    value: InvoiceStatuses.InvoiceFailed.name
  },
  {
    key: 'invoice-created',
    value: InvoiceStatuses.InvoiceCreated.name
  },
  {
    key: 'invoice-sent',
    value: InvoiceStatuses.InvoiceSent.name
  },
  {
    key: 'invoice-closed',
    value: InvoiceStatuses.InvoiceClosed.name
  },
  {
    key: 'invoice-opened',
    value: InvoiceStatuses.InvoiceOpened.name
  }
];

export const InvoiceTab = 'accountInvoicesTab';

export const MAX_LENGTH_INVOICE_COMMENT = 100;
export const MAX_LENGTH_INVOICE_CONTACT_NAME = 128;

export const INVOICE_INSTANCE_SCHEMA = {
  invoiceDate: yup.date().nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required('Invoice Date is required.')
    .typeError('Invalid date format. Date must be in MM/DD/YYYY format.')
    .min(yup.ref('$invoiceDate'), 'Invoice Date must be today’s date or a date in the future.'),
  billingContactName: yup.string().nullable()
    .transform((v) => (v ? v.trim() : v)).required('Contact Name is required.')
    .max(128, 'Contact Name should not be greater than 128 characters.'),
  billingContactEmail: yup.string().nullable().required('Contact Email is required.')
    .email('The Contact Email is not valid.'),
  poNumber: yup.string().nullable(true)
    .transform((v) => (v ? v.trim() : v))
    .max(45, 'Invoice PO Number should not be greater than 45 characters.')
    .when('$poNumber', (poNumber, schema) => (!poNumber ? schema : schema.required('The Invoice PO number is required.'))),
  taxRegistrationNumber: yup.string().nullable(true)
    .transform((v) => (v ? v.trim() : v))
    .max(40, 'Tax Registration should not be greater than 40 characters.')
    .when('$taxRegistrationNumber', (taxRegistrationNumber, schema) => (!taxRegistrationNumber ? schema : schema.required('The Tax Registration Number is required.'))),
  shippingAddress: yup.object().nullable().required('Shipping Address is required.'),
  billingAddress: yup.object().nullable().required('Billing Address is required.'),
  costPerStudent: yup.string().nullable()
    .when('$invoiceInstanceCount', (invoiceInstanceCount, schema) => (invoiceInstanceCount > 1 ?
      schema.required('Cost Per Student is required.')
        .test({
          name: 'costPerStudentMustBeOver0',
          test(value) {
            return Number(value) !== 0;
          },
          message: 'Cost Per Student must be over $0.',
          exclusive: true
        }) : schema))
};

export const INVOICE_EDITOR_SCHEMA = {
  installments: yup.array()
    .min(yup.ref('$installments'), 'Please select one or more installments to create an invoice.'),
  invoices: yup.array(
    yup.object(INVOICE_INSTANCE_SCHEMA),
  ).nullable()
};

export const LEGAL_LANGUAGE_CUSTOMER_INVOICE = [
  {
    name: 'Select Legal Language',
    value: ''
  },
  {
    value: 'TESTING_LANGUAGE',
    name: 'Testing Language'
  },
  {
    value: 'CONTENT_LANGUAGE',
    name: 'Content Language'
  },
  {
    value: 'STANDARD_MULTIPLE_INVOICE_LANGUAGE',
    name: 'Standard Multiple Invoice Language'
  }
];

export const INVOICE_MODE = {
  CREATE: 'create',
  VIEW: 'view',
  EDIT: 'edit'
};

export const INVOICE_FIELDS = [
  {
    key: 'invoiceDate',
    name: 'Invoice Date',
    col: 1
  },
  {
    key: 'billingContactName',
    name: 'Contact Name',
    col: 1
  },
  {
    key: 'billingContactEmail',
    name: 'Contact Email',
    col: 1
  },
  {
    key: 'costPerStudent',
    name: 'Cost Per Student',
    col: 1
  },
  {
    key: 'aprvdCohortCount',
    name: 'Approved Qty',
    col: 1,
    isCreate: false
  },
  {
    key: 'invoiceTotal',
    name: 'Net Amount',
    col: 1,
    isCreate: false
  },
  {
    key: 'poNumber',
    name: 'Invoice PO Number',
    col: 1
  },
  {
    key: 'taxExemptFlag',
    name: 'Tax Exempt',
    col: 1
  },
  {
    key: 'taxRegistrationNumber',
    name: 'Tax Registration #',
    col: 1
  },
  {
    key: 'shippingAddress',
    name: 'Ship To Address',
    col: 2
  },
  {
    key: 'billingAddress',
    name: 'Bill To Address',
    col: 2
  },
  {
    key: 'legalLanguage',
    name: 'Legal Language',
    col: 2
  },
  {
    key: 'comment',
    name: 'Comment'
  }
];

export const INVOICE_POPUP_TYPE = {
  INVOICE_INSTANCE: 0,
  INVOICE_DETAIL: 1,
  INVOICE_REVIEW: 2
};

export const MAX_NO_OF_INVOICES_FOR_SINGLE_INSTALLMENT_DEFAULT = 1;

export const INVOICE_ERROR_TYPES = {
  ERROR_IN_SUBMITTING_INVOICE_TO_BOM: 'error_in_submitting_invoice_to_bom'
};
