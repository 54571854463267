import { lazy } from 'react';
import withExtendProps from 'hocs/with-extend-props/withExtendProps.hoc';
import { ALCAnalytics, ALCRoutePath } from '../constants/hesi.constants';

const SystemErrorMessageCompLazy = lazy(() => import('components/common/system-error-message/SystemErrorMessage.component'));

export const getRoutes = (errorPath: { pageNotFound: string, notAuthorized: string }) => [
  {
    path: errorPath.notAuthorized,
    component: lazy(() => import('pages/error/NotAuthorized.page')),
    roles: [],
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.notAuthorized,
        pageName: 'not-authorized'
      }
    }
  },
  {
    path: errorPath.pageNotFound,
    component: lazy(() => import('pages/error/PageNotFound.page')),
    roles: [],
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.pageNotFound,
        pageName: 'page-not-found'
      }
    }
  }
];

const getExamOrderMoreSecurityRoutes = () => [
  ...getRoutes(ALCRoutePath.examOrderMore.error),
  {
    path: ALCRoutePath.examOrderMore.error.examNotFound,
    component: withExtendProps({
      messageId: 'EXAM_NOT_FOUND'
    })(SystemErrorMessageCompLazy),
    roles: [],
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.examNotFound,
        pageName: 'exam-not-found'
      }
    }
  },
  {
    path: ALCRoutePath.examOrderMore.error.invalidProgam,
    component: withExtendProps({
      messageId: 'HESI_PROGRAM_INVALID'
    })(SystemErrorMessageCompLazy),
    roles: [],
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.invalidProgam,
        pageName: 'invalid-program'
      }
    }
  },
  {
    path: ALCRoutePath.examOrderMore.error.programNotFound,
    component: withExtendProps({
      messageId: 'HESI_PROGRAM_NOT_FOUND'
    })(SystemErrorMessageCompLazy),
    roles: [],
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.pageNotFound,
        pageName: 'program-not-found'
      }
    }
  },
  {
    path: ALCRoutePath.examOrderMore.error.examOrderItemExpiredOrClose,
    component: withExtendProps({
      messageId: 'HESI_EXAM_ORDER_ITEM_CLOSED_OR_EXPIRED'
    })(SystemErrorMessageCompLazy),
    roles: [],
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.expiredOrderClosedOrderItem,
        pageName: 'expired-order-closed-order-item'
      }
    }
  }
];

export const HESISecutityRoutes = {
  getRoutes,
  getExamOrderMoreSecurityRoutes
};
