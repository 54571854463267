import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REDIRECT_URL, RoutePath } from 'constants/app.constant';
import { Loader } from '@els/els-react--loader';
import { securitySelectors } from '../../redux/security';
import { locationAsyncActions } from '../../redux/location';
import { appSelectors } from '../../redux/app';
import { StorageHelper } from '../../helpers/storage.helper';

const withAuthorize = (allowRoles = []) => WrappedComponent => (props) => {
  const isLoggedIn = useSelector(securitySelectors.isLoggedIn);
  const isLoading = useSelector(appSelectors.isLoading);
  const isAuthorized = useSelector(securitySelectors.isAuthorized(allowRoles));
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isLoading && !isLoggedIn) {
      const redirectUrl = `${props.location.pathname}${props.location.search || ''}`;
      StorageHelper.setItem(REDIRECT_URL, redirectUrl);
      dispatch(locationAsyncActions.redirect(RoutePath.security.login));
    }
    if (isLoggedIn && !isAuthorized && !isLoading) {
      dispatch(locationAsyncActions.redirect(RoutePath.error.notAuthorized));
    }
  }, [isLoggedIn, isAuthorized, isLoading]);

  return (isLoggedIn && isAuthorized) ? <WrappedComponent {...props} /> : <><Loader isBlocking isVisible /></>;
};

export default withAuthorize;
