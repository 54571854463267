import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { Redirect, Switch, generatePath, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { ALCRoutePath } from 'modules/hesi/constants/hesi.constants';
import { examOrderActions, examOrderSelectors } from 'modules/hesi/redux/exam-order';
import { locationAsyncActions } from 'redux/location';
import { examOrderServices } from 'modules/hesi/services';
import withUseLoading from 'utilities/with-loading/withUseLoading';
import { PAYMENT_METHODS } from 'modules/hesi/constants/exam-order.constant';
import HESILayout from 'modules/hesi/components/layout/HESILayout.component';
import ErrorBoundary from 'components/error-boundary/ErrorBoundary.component';
import { createExamOrderRoutes } from '../../utilities/route/route.utility';
import { ALCRoutes } from '../../routes/hesi.routes';
import { fetchHESIProgramList, fetchProductMetadata } from '../../redux/exam-order/exam-order.actions';

const examOrderRoutes = createExamOrderRoutes(ALCRoutes.getExamOrderRoutes());

const ExamOrderHomePage = () => {
  const isAuthorized = useSelector(examOrderSelectors.isAuthorizeExamOrder);
  const dispatch = useDispatch();
  const examOrder = useSelector(examOrderSelectors.getExamOrder);
  const location = useLocation();
  const pagingOptions = useSelector(examOrderSelectors.getExamPagingOptions());
  const examOrderItems = useSelector(examOrderSelectors.getExamOrderItems);
  const isExamOrderMore = useSelector(examOrderSelectors.isExamOrderMore);

  const isRedirectToExamCatalogPage = () => {
    if (!isEmpty(pagingOptions) || !isEmpty(examOrderItems)) {
      const { selectedProgramTypes, selectedAssessmentTypes } = pagingOptions;
      return ((examOrderItems && examOrderItems.length > 0) ||
        (selectedProgramTypes && selectedProgramTypes.length > 0) ||
        (selectedAssessmentTypes && selectedAssessmentTypes.length > 0));
    }
    return false;
  };

  const getPaymentDetails = () =>
    withUseLoading(async () => {
      try {
        await examOrderServices.getPaymentOrderDetails(examOrder.tspHesiExamPymtOrderId, examOrder.contextId);
        dispatch(locationAsyncActions.redirect(generatePath(ALCRoutePath.examOrder.examOrderCartConfirm)));
      } catch (error) {
        dispatch(examOrderActions.cleanUpExamOrderId());
      }
    });

  const resetExamPaymentInfo = () => {
    if (isEmpty(examOrder) || (isEmpty(examOrder.tspHesiExamPymtOrderId) && (isExamOrderMore && isEmpty(examOrder.examOrderId)))) {
      return;
    }

    if (isExamOrderMore) {
      const ignoreOrderMoreURLs = [
        generatePath(ALCRoutePath.examOrder.programTypes),
        generatePath(ALCRoutePath.examOrder.examTypes),
        generatePath(ALCRoutePath.examOrder.examCatalog),
      ];
      if (ignoreOrderMoreURLs.includes(location.pathname)) {
        dispatch(examOrderActions.setExamOrderItems([]));
        dispatch(examOrderActions.resetExamOrder());
      }
      return;
    }

    const { tspHesiExamPymtOrderId } = examOrder;
    const ignoreURLs = [
      generatePath(ALCRoutePath.examOrder.examOrderProcessPayment, { tspHesiExamPymtOrderId }),
      generatePath(ALCRoutePath.examOrder.examOrderProcessCancel, { tspHesiExamPymtOrderId })
    ];
    if (examOrder.paymentMethod === PAYMENT_METHODS.CREDIT_CARD && examOrder.contextId && !ignoreURLs.includes(location.pathname)) {
      dispatch(getPaymentDetails());
    }
  };

  const initData = () => {
    dispatch(fetchProductMetadata());
    dispatch(fetchHESIProgramList());
    resetExamPaymentInfo();
  };

  useEffect(() => {
    if (isAuthorized) {
      initData();
    }
  }, [isAuthorized]);

  const isRedirectToExamCatalog = isRedirectToExamCatalogPage();

  return (
    <HESILayout>
      <ErrorBoundary>
        <Switch>
          {examOrderRoutes}
          {isRedirectToExamCatalog && <Redirect exact from={ALCRoutePath.examOrder.home} to={ALCRoutePath.examOrder.examCatalog} />}
          {!isRedirectToExamCatalog && <Redirect exact from={ALCRoutePath.examOrder.home} to={ALCRoutePath.examOrder.programTypes} />}
          <Redirect to={ALCRoutePath.error.pageNotFound} />
        </Switch>
      </ErrorBoundary>
    </HESILayout>
  );
};

export default ExamOrderHomePage;
