import { omit, cloneDeep, get, intersection, isEmpty } from 'lodash';
import { ELSLoggingService } from '@els/els-ui-common-react';
import { ERROR_MESSAGES, ERROR_MESSAGE_BY_STATUS, ERROR_MESSAGE_PATH_MAPPING } from '../../constants/error.constant';
import { getErrorMessage } from '../app/app.utility';

const getBrowserDetails = () => omit(cloneDeep(navigator), 'mimeTypes');

export const logError = ({ err, previousState, previousStateParams }) => {
  const details = {
    error: err,
    browser: getBrowserDetails(),
    previousState,
    previousStateParams
  };
  ELSLoggingService.error('error.utility', details);
};

export const promiseErrorHandler = error => {
  ELSLoggingService.error('', `Invoke service error: ${error.message}`, error);
  return { data: [] };
};

const getErrorMessageBySpecialType = error => {
  const type = get(error, 'response.data.errors[0].type');
  const pathObj = ERROR_MESSAGE_PATH_MAPPING.find(item => item.errorType === type);
  if (pathObj) {
    return get(error, pathObj.path);
  }

  return null;
};

const generateErrorMessageBySpecialStatus = (error) => {
  const status = get(error, 'response.status');
  const errorByStatus = ERROR_MESSAGE_BY_STATUS.find(item => item.status === status);
  if (errorByStatus) {
    return errorByStatus.message;
  }

  return null;
};

const generateErrorMessage = (message, fieldNameObj?: { [x: string]: string }) => {
  if (isEmpty(fieldNameObj)) {
    return message;
  }
  const fieldNames = Object.keys(fieldNameObj);
  const field = intersection(fieldNames, message.split(' '));
  return field.length > 0 ? message.replace(field[0], fieldNameObj[field[0]]) : message;
};

export const getMessageBasedOnErrorType = (error, defaultErrorMessage?: string, fieldNameObj?: { [x: string]: string }) => {
  const type = get(error, 'response.data.errors[0].type');
  if (type) {
    const foundErrorMessage = ERROR_MESSAGES.find(item => item.type === type);
    if (foundErrorMessage) {
      return foundErrorMessage.message;
    }
  }

  const errorMessage = getErrorMessageBySpecialType(error);
  if (errorMessage) {
    return generateErrorMessage(errorMessage, fieldNameObj);
  }

  const errorMessageBySpecialStatus = generateErrorMessageBySpecialStatus(error);
  if (errorMessageBySpecialStatus) {
    return errorMessageBySpecialStatus;
  }

  if (defaultErrorMessage) {
    return defaultErrorMessage;
  }

  const message = getErrorMessage(error);
  return generateErrorMessage(message, fieldNameObj);
};

export const errorUtils = { logError, promiseErrorHandler };
