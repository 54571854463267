import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ALCConstants } from 'modules/hesi/constants/hesi.constants';
import { HesiInvoiceEditorModel, HesiInvoiceFormModel } from 'modules/hesi/models/hesiInvoice.model';
import { ExamOrderModel } from 'modules/hesi/models/examOrder.model';

export const REDUCER_ID = ALCConstants.redux.EXAM_ORDER_DASHBOARD;

const initialState = {
  hesiInvoiceEditor: {
    hesiInvoices: [],
    hesiInvoiceActiveIndex: null,
    validationErrors: {},
    responseError: ''
  },
  examOrderDetails: null
};

export const examOrderDashboardSlice = createSlice({
  name: 'examOrderDashboard',
  initialState,
  reducers: {
    initialHesiInvoiceEditor(state, action: PayloadAction<HesiInvoiceFormModel[]>) {
      return {
        ...state,
        hesiInvoiceEditor: {
          ...state.hesiInvoiceEditor,
          hesiInvoices: action.payload,
          hesiInvoiceActiveIndex: 0 }
      };
    },
    updateHesiInvoices(state, action: PayloadAction<HesiInvoiceFormModel>) {
      const updatedInvoices = state.hesiInvoiceEditor.hesiInvoices.map(item =>
        (item.programId === action.payload.programId ? action.payload : item));
      return {
        ...state,
        hesiInvoiceEditor: {
          ...state.hesiInvoiceEditor,
          hesiInvoices: updatedInvoices,
        }
      };
    },
    updateHesiInvoiceActiveIndex(state, action: PayloadAction<number>) {
      return {
        ...state,
        hesiInvoiceEditor: {
          ...state.hesiInvoiceEditor,
          hesiInvoiceActiveIndex: action.payload
        }
      };
    },
    updateHesiInvoiceValidationError(state, action: PayloadAction<{ [key: string]: string }>) {
      return {
        ...state,
        hesiInvoiceEditor: {
          ...state.hesiInvoiceEditor,
          validationErrors: action.payload
        }
      };
    },
    updateHesiInvoiceResponseError(state, action) {
      return {
        ...state,
        hesiInvoiceEditor: {
          ...state.hesiInvoiceEditor,
          responseError: action.payload
        }
      };
    },
    updateHesiInvoiceEditor(state, action: PayloadAction<HesiInvoiceEditorModel>) {
      return {
        ...state,
        hesiInvoiceEditor: {
          ...state.hesiInvoiceEditor,
          ...action.payload
        }
      };
    },
    resetHesiInvoiceResponseError(state) {
      return {
        ...state,
        hesiInvoiceEditor: {
          ...state.hesiInvoiceEditor,
          responseError: ''
        }
      };
    },
    resetHesiInvoiceEditor(state) {
      return {
        ...state,
        hesiInvoiceEditor: {
          hesiInvoices: [],
          hesiInvoiceActiveIndex: null,
          responseError: '',
          validationErrors: {}
        }
      };
    },
    setExamOrderDetails(state, action: PayloadAction<ExamOrderModel>) {
      return {
        ...state,
        examOrderDetails: action.payload
      };
    },
  }
});

export const examOrderDashboardActions = examOrderDashboardSlice.actions;
export const examOrderDashboardReducer = examOrderDashboardSlice.reducer;
