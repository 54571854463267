import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ALCConstants } from '../../constants/hesi.constants';

export const REDUCER_ID = ALCConstants.redux.EXAM_ORDER_STATE;

const initialState = {
  accessRoles: [],
  hesiAlcOrderPilotSupport: false
};

export const hesiSlice = createSlice({
  name: 'hesi',
  initialState,
  reducers: {
    setHesiAccessRoles(state, action: PayloadAction<string>) {
      const hesiAccessRoles = action.payload ? action.payload.split(',') : [];
      return {
        ...state,
        accessRoles: hesiAccessRoles
      };
    },
    saveSearchExamOrderPayload(state, action) {
      return {
        ...state,
        examOrderRequestPayload: action.payload
      };
    },
    setHesiConfig(state, action) {
      return {
        ...state,
        maxProgramsNumber: action.payload.maxProgramsNumber
      };
    },
    setHesiAlcOrderPilotSupport(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        hesiAlcOrderPilotSupport: action.payload
      };
    }
  }
});

export const hesiActions = hesiSlice.actions;
export const hesiReducer = hesiSlice.reducer;
