import { tspServiceApi } from 'helpers/RestClient.helper';
import { HesiInvoiceModel, HesiInvoiceStatusModel, HesiInvoiceFormModel } from '../models/hesiInvoice.model';

type GetHesiInvoicesByIdResponse = { tspHesiExamPymtOrderId: number; hesiInvoices: HesiInvoiceModel[] };
export const getHesiInvoices = (tspHesiExamPymtOrderId: number) =>
  tspServiceApi.get(`/hesi/alc/admin/examPymtOrders/${tspHesiExamPymtOrderId}/hesiInvoices`) as Promise<GetHesiInvoicesByIdResponse>;

export const fetchHesiInvoiceStatusHistory = (tspHesiInvoiceId: number) =>
  tspServiceApi.get(`/hesi/alc/admin/hesiInvoices/${tspHesiInvoiceId}/statusHistory`) as Promise<{
    tspHesiInvoiceId: number;
    statusHistory: HesiInvoiceStatusModel[];
  }>;

export const getHesiInvoiceById = (tspHesiInvoiceId: number) =>
  tspServiceApi.get(`/hesi/alc/admin/hesiInvoices/${tspHesiInvoiceId}`) as Promise<HesiInvoiceModel>;

export const createExamOrderInvoice = (tspHesiExamPymtOrderId: number, invoices:HesiInvoiceFormModel[]) => {
  const hesiInvoiceEditor = { tspHesiExamPymtOrderId, invoices };
  return tspServiceApi.post(`/hesi/alc/admin/examPymtOrders/${tspHesiExamPymtOrderId}/hesiInvoices`, { data: hesiInvoiceEditor });
};
