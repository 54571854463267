import { lazy } from 'react';
import withExtendProps from 'hocs/with-extend-props/withExtendProps.hoc';
import { ALCAnalytics, ALCRoutePath } from '../constants/hesi.constants';
import { ORDER_CART_TABS } from '../constants/exam-order.constant';

export const getExamOrderRoutes = () => [
  {
    path: ALCRoutePath.examOrder.examOrderCartReview,
    component: withExtendProps({
      activeTab: ORDER_CART_TABS.REVIEW
    })(lazy(() => import('modules/hesi/pages/exam-order-management/ExamOrderManagement.page'))),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.orderCartReview,
        pageName: 'alc-order-cart:review'
      }
    }
  },
  {
    path: ALCRoutePath.examOrder.examOrderCartPayment,
    component: withExtendProps({
      activeTab: ORDER_CART_TABS.PAYMENT
    })(lazy(() => import('modules/hesi/pages/exam-order-management/ExamOrderManagement.page'))),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.orderCartPayment,
        pageName: 'alc-order-cart:payment'
      }
    }
  },
  {
    path: ALCRoutePath.examOrder.examOrderCartConfirm,
    component: withExtendProps({
      activeTab: ORDER_CART_TABS.CONFIRM
    })(lazy(() => import('../pages/exam-order-management/ExamOrderManagement.page'))),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.orderCartConfirm,
        pageName: 'alc-order-cart:confirm'
      }
    }
  },
  {
    path: ALCRoutePath.examOrder.examTypes,
    component: lazy(() => import('../pages/exam-selection/ExamTypeSelection.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.examTypes,
        pageName: 'alc-exam-types'
      }
    }
  },
  {
    path: ALCRoutePath.examOrder.examCatalog,
    component: lazy(() => import('../pages/exam-selection/ExamSelection.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.examCatalog,
        pageName: 'alc-exam-catalog'
      }
    }
  },
  {
    path: ALCRoutePath.examOrder.examOrderProcessCancel,
    component: lazy(() => import('../pages/payment-process/PaymentProcess.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.orderProcessCancel,
        pageName: 'alc-order-process-cancel'
      }
    }
  },
  {
    path: ALCRoutePath.examOrder.examOrderProcessPayment,
    component: lazy(() => import('../pages/payment-process/PaymentProcess.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.orderProcessPayment,
        pageName: 'alc-order-process-payment'
      }
    }
  },
  {
    path: ALCRoutePath.examOrder.programTypes,
    component: lazy(() => import('../pages/exam-selection/ProgramTypeSelection.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.orderHESIExams,
        pageName: 'alc-program-types'
      }
    }
  }
];

export const getExamOrderHistoryRoutes = () => [
  {
    path: ALCRoutePath.examOrderHistory.examOrderHistoryDetails,
    component: lazy(() => import('../pages/exam-order-history/ExamOrderHistoryDetailsPage.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.examOrderHistoryDetails,
        pageName: 'alc-exam-order-history-details'
      }
    }
  },
  {
    path: ALCRoutePath.examOrderHistory.examOrderHistory,
    component: lazy(() => import('../pages/exam-order-history/ExamOrderHistory.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.examOrderHistory,
        pageName: 'alc-exam-order-history'
      }
    }
  }
];

export const getExamOrderDashboardRoutes = () => [
  {
    path: ALCRoutePath.examOrderDashboard.examOrderDetails,
    component: lazy(() => import('../pages/exam-order-dashboard/exam-order-details/ExamOrderDetailsPage.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.examOrderDetails,
        pageName: 'alc-exam-order-details'
      }
    }
  },
  {
    path: ALCRoutePath.examOrderDashboard.examOrderDashboard,
    component: lazy(() => import('../pages/exam-order-dashboard/ExamOrderDashboardPage.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.examOrderDashboard,
        pageName: 'alc-exam-order-dashboard'
      }
    }
  }
];

export const getExamGroupStudentRegistrationRoutes = () => [
  {
    path: ALCRoutePath.examGroup.studentRegistration,
    component: lazy(() => import('../pages/exam-group/student-registration/StudentRegistration.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.examGroupStudentRegistration,
        pageName: 'alc-exam-group-student-registration'
      }
    }
  }
];

export const getExamOrderMoreRoutes = () => [
  {
    path: ALCRoutePath.examOrderMore.examOrderMore,
    component: lazy(() => import('../pages/exam-order-more/ExamOrderMore.page')),
    data: {
      analytics: {
        pageType: ALCAnalytics.pageType.examOrderMore,
        pageName: 'alc-exam-order-more'
      }
    }
  }
];

export const ALCRoutes = {
  getExamOrderRoutes,
  getExamOrderHistoryRoutes,
  getExamOrderDashboardRoutes,
  getExamGroupStudentRegistrationRoutes,
  getExamOrderMoreRoutes
};
