import { isEqual, uniq, intersection, findIndex } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ALCConstants, defaultExamPagingOptions } from '../../constants/hesi.constants';
import { ProgramModel, PagingOptions, ProgramTypeModel } from '../../models/exam.model';
import { ExamOrderItemEditorModel, ExamOrderEditorModel } from '../../models/examOrder.model';

export const REDUCER_ID = ALCConstants.redux.EXAM_ORDER_STATE;

const initialState = {
  isAuthorized: null,
  programTypes: null,
  examProducts: null,
  pagingOptions: defaultExamPagingOptions,
  examOrderItems: null,
  examOrderItemSelected: null,
  programs: [],
  examOrder: null,
  isExamOrderMore: false
};

export const examOrderSlice = createSlice({
  name: 'examOrder',
  initialState,
  reducers: {
    restoreExamOrderState(state, action) {
      return {
        ...state,
        ...action.payload,
        isAuthorized: null
      };
    },
    setAuthorizeExamOrder(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isAuthorized: action.payload
      };
    },
    fetchProductMetadataSuccess(state, action: PayloadAction<ProgramTypeModel[]>) {
      return {
        ...state,
        programTypes: action.payload
      };
    },
    setExamOrderItems(state, action: PayloadAction<ExamOrderItemEditorModel[]>) {
      return {
        ...state,
        examOrderItems: action.payload
      };
    },
    updateExamOrderItems(state, action: PayloadAction<ExamOrderItemEditorModel>) {
      let examOrderItems = state.examOrderItems && state.examOrderItems.length > 0 ? [...state.examOrderItems] : [];
      const index = findIndex(examOrderItems, { productId: action.payload.productId });
      if (index === -1) {
        examOrderItems.unshift(action.payload);
      } else {
        examOrderItems = examOrderItems.map(order => (order.productId === action.payload.productId ? { ...action.payload } : order));
      }
      return {
        ...state,
        examOrderItems
      };
    },
    setExamOrderItemSelected(state, action: PayloadAction<ExamOrderItemEditorModel>) {
      return {
        ...state,
        examOrderItemSelected: action.payload
      };
    },
    setPrograms(state, action: PayloadAction<ProgramModel[]>) {
      return {
        ...state,
        programs: action.payload
      };
    },
    fetchExamProductsDataSuccess(state, action) {
      return {
        ...state,
        examProducts: action.payload
      };
    },
    updateExamPagingOptions(state, action: PayloadAction<PagingOptions>) {
      const pagingOptions = {
        ...(state.pagingOptions || {}),
        ...action.payload
      };

      const { selectedProgramTypes } = pagingOptions;
      const prevSelectedProgramTypes = (state.pagingOptions && state.pagingOptions.selectedProgramTypes) || [];
      const prevSelectedAssessmentTypes = (state.pagingOptions && state.pagingOptions.selectedAssessmentTypes) || [];
      const programTypes = state.programTypes && state.programTypes.length > 0 ? [...state.programTypes] : [];

      if (programTypes.length > 0 && !isEqual(selectedProgramTypes, prevSelectedProgramTypes) && prevSelectedAssessmentTypes.length > 0) {
        const filteredProgramTypes = programTypes.filter(type => selectedProgramTypes.includes(type.programTypeId));
        const examTypes = filteredProgramTypes.reduce((result, programType) => [...result, ...programType.examTypes], []);
        const assessmentTypeIds = uniq(examTypes.map(examType => examType.assessmentType.assessmentTypeId));

        const assessmentTypeIdData = intersection(assessmentTypeIds, prevSelectedAssessmentTypes);

        if (!isEqual(assessmentTypeIdData, prevSelectedAssessmentTypes)) {
          pagingOptions.selectedAssessmentTypes = assessmentTypeIdData;
        }
      }

      return {
        ...state,
        pagingOptions
      };
    },
    setExamOrder(state, action: PayloadAction<ExamOrderEditorModel>) {
      return {
        ...state,
        examOrder: action.payload
      };
    },
    setExamOrderMoreActive(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isExamOrderMore: action.payload
      };
    },
    resetExamOrder(state) {
      return {
        ...state,
        pagingOptions: defaultExamPagingOptions,
        examOrderItems: null,
        examOrderItemSelected: null,
        examOrder: null,
        isExamOrderMore: false
      };
    },
    cleanUpExamOrderId(state) {
      const newExamOrder = { ...state.examOrder };
      delete newExamOrder.tspHesiExamPymtOrderId;
      delete newExamOrder.contextId;
      delete newExamOrder.examOrderId;
      return {
        ...state,
        examOrder: {
          ...newExamOrder
        }
      };
    },
  }
});

export const examOrderActions = examOrderSlice.actions;
export const examOrderReducer = examOrderSlice.reducer;
