import { ALCConstants } from '../constants/hesi.constants';
import { examOrderActions, examOrderReducer } from './exam-order';
import { StoreConfig } from '../../../redux/app.store';
import { hesiReducer } from './hesi/hesi.reducer';
import { examOrderDashboardReducer } from './exam-order-dashboard';

const hesiStoreConfig : StoreConfig = {
  actionTypesToStore: [
    'examOrder/setProgramTypesSelected',
    'examOrder/setExamTypesSelected',
    'examOrder/setExamOrderItems',
    'examOrder/setExamOrderItemSelected',
    'examOrder/updateExamPagingOptions',
    'examOrder/setExamOrder',
    'examOrder/resetExamOrder',
    'examOrder/cleanUpExamOrderId'
  ],
  statesToBeStoredInLocalStorage: {
    [ALCConstants.redux.EXAM_ORDER_STATE]: examOrderActions.restoreExamOrderState
  },
  reducers: {
    [ALCConstants.redux.EXAM_ORDER_STATE]: examOrderReducer,
    [ALCConstants.redux.HESI_STATE]: hesiReducer,
    [ALCConstants.redux.EXAM_ORDER_DASHBOARD]: examOrderDashboardReducer
  }
};

export default hesiStoreConfig;
